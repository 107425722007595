













import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  props: {
    id: {
      type: String,
      required: false,
      default: "",
    },
    additionalServicesModule: {
      type: Boolean,
      required: false,
      default: false,
    },
    additionalServicesModuleForms: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup() {
    return {};
  },
});
